import React from "react";

export default function Gallery(props) {
    React.useEffect(() => {
        props.setPageTitle('Our gallery');
        props.setPageDescription('Please have a look at our gallery to see images and videos of our learning programmes.');
    }, []);
    return <React.Fragment>
        <br />

        <section id="gallery" className="padding">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="btn our-btn active" id="pills-img-tab" data-toggle="pill" href="#pills-img" role="tab" aria-controls="pills-img" aria-selected="true">Images</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn our-btn" id="pills-vid-tab" data-toggle="pill" href="#pills-vid" role="tab" aria-controls="pills-vid" aria-selected="false">Videos</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane show active" id="pills-img" role="tabpanel" aria-labelledby="pills-img-tab">
                        <div className="row text-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/0.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/0.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/1.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/1.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/2.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/2.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/3.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/3.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/4.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/4.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/5.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/5.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/6.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/6.png"} alt="..." />
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/7.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/7.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/8.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/8.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/9.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/9.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/10.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/10.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/11.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/11.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/12.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/12.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/13.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/13.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/14.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/14.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/15.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/15.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/16.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/16.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/17.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/17.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/18.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/18.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/19.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/19.png"} alt="..." />
                                </a>
                            </div>
                          
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-vid" role="tabpanel" aria-labelledby="pills-vid-tab">
                        <div className="container">

                            <div className="col-lg-10 mx-auto py-4">
                                <div className="embed__container">
                                    <div id="player"></div>
                                </div>
                                <div className="carousel__wrap mt-3">
                                    <div className="owl-carousel">
                                    <div data-video="bYoZqLFuGak" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/bYoZqLFuGak/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="6yQl0x6oFNo" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/6yQl0x6oFNo/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="1PpJg3cMdU0" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/1PpJg3cMdU0/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="sawojsyiu40" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/sawojsyiu40/hqdefault.jpg" alt='...'
                                            />
                                        </div>

                                        <div data-video="R1uyeqE9G20" className="item video-thumb active">
                                            <img
                                                src="https://img.youtube.com/vi/R1uyeqE9G20/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="zmu3-jc8sjA" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/zmu3-jc8sjA/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="GemEH68gZIw" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/GemEH68gZIw/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        -
                                        <div data-video="qWg2TbCm8ZM" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/qWg2TbCm8ZM/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="_V_fUmzfzQk" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/_V_fUmzfzQk/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="PekipzKAuuc" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/PekipzKAuuc/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="NmmEki2sh2c" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/NmmEki2sh2c/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="dGZjXNTaUuA" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/dGZjXNTaUuA/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                      

                                        <div data-video="r90ncqEprNs" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/r90ncqEprNs/hqdefault.jpg" alt='...'
                                            />
                                        </div>

                                        <div data-video="kd_MZbkugy8" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/kd_MZbkugy8/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="6bgqMBxuecc" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/6bgqMBxuecc/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        <div data-video="fATAfKadzQI" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/fATAfKadzQI/hqdefault.jpg" alt='...'
                                            />
                                        </div>


                                        <div data-video="KiXgzLKxpwQ" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/KiXgzLKxpwQ/hqdefault.jpg" alt='...'
                                            />
                                        </div>


                                        <div data-video="gZNmMVBwbjQ" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/gZNmMVBwbjQ/hqdefault.jpg" alt='...'
                                            />
                                        </div>

                                        <div data-video="9ElMZZ2ngGY" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/9ElMZZ2ngGY/hqdefault.jpg" alt='...'
                                            />
                                        </div>

                                        <div data-video="Nmxvh277vTI" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/Nmxvh277vTI/hqdefault.jpg" alt='...'
                                            />
                                        </div>

                                        <div data-video="dGZjXNTaUuA" className="item video-thumb">
                                            <img
                                                src="https://img.youtube.com/vi/dGZjXNTaUuA/hqdefault.jpg" alt='...'
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment >
}

