import React from "react";

export default function Contact(props) {
    const team1 = [
        {
            name: 'Mr Anvarsha Abdulkalam ',
            position: 'Managing Director,  ABKIAB',
            icon: 'user-male'
        },
        {
            name: 'Mr Aboobakker Siddique',
            position: 'Project Engineer, Alderley, UK',
            icon: 'user-male'
        },
        {
            name: 'Ms Sindhu Sivadas',
            position: 'Managing Director, Matrix Abacus Academy, Kerala',
            icon: 'user-female'
        },
        {
            name: 'Dr Betty Elezebeth Samuel',
            position: 'PhD in Computer Science',
            icon: 'user-female'
        },
    ]
    const team2 = [

        {
            name: 'Dr Nithinsha Shajahan',
            position: 'PhD in Computer Science and Engineering',
            icon: 'user-female'
        },
        {
            name: 'Ms Hazeena M',
            position: 'Bachelors in Telecommunications',
            icon: 'user-female'
        },
        {
            name: 'Ms Jezna Ansari',
            position: 'Masters in Software Engineering',
            icon: 'user-female'
        },
        {
            name: 'Ms Shabana Anish',
            position: 'Teaching Faculty, Habitat School, Ajman, UAE',
            icon: 'user-female'
        },

    ]
    function submitForm(event) {
        event.preventDefault();
        document.getElementById('contact-loader').style.display = '';
        fetch(props.url + 'contact/', {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/Json'
            },
            body: JSON.stringify({
                'p_f_name': document.getElementById('p-f-name').value,
                'p_l_name': document.getElementById('p-l-name').value,
                'p_contact': document.getElementById('p-contact').value,
                'p_email': document.getElementById('p-email').value,
                'country': document.getElementById('country').value,
                's_f_name': document.getElementById('s-f-name').value,
                's_l_name': document.getElementById('s-l-name').value,
                'dob': document.getElementById('dob').value,
                'start': document.getElementById('start').value,
                'course': document.getElementById('course').value,
                'message': document.getElementById('message').value,
            }),
        }).then(resp => resp.json()).then(resp => {
            if (resp.error) {
                alert(resp.message ? resp.message : resp.error.message ? resp.error.message : resp.error);
            } else {
                console.log(resp)
                alert('Thanks! We will contact you shortly');
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            }
        }).then(() => {
            document.getElementById('contact-loader').style.display = '';

        });
    }
    React.useEffect(() => {
        props.setPageTitle('Get in touch with us.');
        props.setPageDescription('Please fill in this form below and we will provide you with more information.');

    }, []);
    return <React.Fragment>
        <section id="contact" className="contact container">
            <div data-aos="fade-up">
                <iframe style={{ "border": "0", "width": "100%", "height": "350px" }}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3941.4373909500955!2d76.64573669433594!3d8.931779861450195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05e2b3631e172b%3A0xba535254960767ba!2sChandanathope%2C%20Kottamkara%2C%20Kerala!5e0!3m2!1sen!2sin!4v1696332692731!5m2!1sen!2sin"
                    frameBorder="0" allowFullScreen>
                </iframe>
            </div>
            <div className="container" data-aos="fade-up">
                <section id="trainers" className="trainers">
                    <div className="container" data-aos="fade-up">
                        <center>
                            <div className="section-title">
                                <h2><strong>Our Expert Team</strong></h2>
                                <span>&nbsp;</span>
                            </div>
                        </center>
                        <div className="row d-flex justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                            {team1.map(i => {
                                return <React.Fragment key={i.name}>
                                    <div className="card shadow-sm col-sm col-md-2 d-flex align-items-stretch our-team" style={{ 'width': '24%' }}>
                                        <div className="container member">
                                            <br />
                                            <img src={"assets/img/" + i.icon + ".png"} className="img-fluid" alt="" />
                                            <div className="member-content text-center">
                                                <br />
                                                <h5><strong>{i.name}</strong></h5>
                                                <span>{i.position}</span>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            })}
                        </div>
                        <br />
                        <div className="row d-flex justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                            {team2.map(i => {
                                return <React.Fragment key={i.name}><div className="card shadow-sm col-sm col-md-2 d-flex align-items-stretch our-team" style={{ 'width': '24%' }}>
                                    <div className="container member">
                                        <br />
                                        <img src={"assets/img/" + i.icon + ".png"} className="img-fluid" alt="" />
                                        <div className="member-content text-center">
                                            <br />
                                            <h5><strong>{i.name}</strong></h5>
                                            <span>{i.position}</span>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            })}
                        </div>
                    </div>
                </section>

                <div className="row">
                
                    <div className="col-lg-12 mt-lg-0 shadow-sm">
                        <form method="post" role="form" className="php-email-form shadow-sm" onSubmit={(event) => submitForm(event)}>
                            <div className={"alert alert-success"}>
                                If you're a current student or parent, to get in touch with our customer service team, please
                                click <a href="https://wa.me/919995628360" target="_blank" className="btn our-btn"><i className="bi bi-whatsapp"></i> here</a>
                            </div>
                            <br />
                            <div className={"alert alert-primary text-center"}>
                                <b>Parent/Guardian details</b>
                            </div>
                            <div className="card border register-card">
                                <div className="container">
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>First name</label>
                                            <input type="text" className="form-control" id="p-f-name"
                                                placeholder="First name" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Last name</label>
                                            <input type="text" className="form-control" id="p-l-name"
                                                placeholder="Last name" required />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <label>Contact number</label>
                                            <input type="number" className="form-control" id="p-contact"
                                                placeholder="Contact number" required />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <label>Email address</label>
                                            <input type="email" className="form-control" id="p-email"
                                                placeholder="Email id" required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mt-3 mt-md-0">
                                            <label>Country of residence</label>
                                            <select id={"country"} className="form-control" required>
                                                <option>--Country of residence--</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="American Samoa">American Samoa</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Anguilla">Anguilla</option>
                                                <option value="Antartica">Antarctica</option>
                                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Bouvet Island">Bouvet Island</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="British Indian Ocean Territory">British Indian Ocean Territory
                                                </option>
                                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Christmas Island">Christmas Island</option>
                                                <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Congo">Congo, the Democratic Republic of the</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                <option value="Croatia">Croatia (Hrvatska)</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="East Timor">East Timor</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                <option value="Faroe Islands">Faroe Islands</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="France Metropolitan">France, Metropolitan</option>
                                                <option value="French Guiana">French Guiana</option>
                                                <option value="French Polynesia">French Polynesia</option>
                                                <option value="French Southern Territories">French Southern Territories</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Greenland">Greenland</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                <option value="Holy See">Holy See (Vatican City State)</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Iran">Iran (Islamic Republic of)</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Democratic People's Republic of Korea">Korea, Democratic People's
                                                    Republic of
                                                </option>
                                                <option value="Korea">Korea, Republic of</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Lao">Lao People's Democratic Republic</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Macau">Macau</option>
                                                <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Martinique">Martinique</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mayotte">Mayotte</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Micronesia">Micronesia, Federated States of</option>
                                                <option value="Moldova">Moldova, Republic of</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montserrat">Montserrat</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Namibia">Namibia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                <option value="New Caledonia">New Caledonia</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Niue">Niue</option>
                                                <option value="Norfolk Island">Norfolk Island</option>
                                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Pitcairn">Pitcairn</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Reunion">Reunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russia">Russian Federation</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                <option value="Saint LUCIA">Saint LUCIA</option>
                                                <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="South Georgia">South Georgia and the South Sandwich Islands
                                                </option>
                                                <option value="Span">Spain</option>
                                                <option value="SriLanka">Sri Lanka</option>
                                                <option value="St. Helena">St. Helena</option>
                                                <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syria">Syrian Arab Republic</option>
                                                <option value="Taiwan">Taiwan, Province of China</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania">Tanzania, United Republic of</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tokelau">Tokelau</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Emirates">United Arab Emirates</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States">United States</option>
                                                <option value="United States Minor Outlying Islands">United States Minor
                                                    Outlying Islands
                                                </option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Vietnam">Viet Nam</option>
                                                <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                <option value="Western Sahara">Western Sahara</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                        </div>

                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className={"alert alert-success text-center"}>
                                <b>Student details</b>
                            </div>
                            <div className="card border register-card">
                                <div className="container">
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>First name</label>
                                            <input type="text" className="form-control" id="s-f-name"
                                                placeholder="First name" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Last name</label>
                                            <input type="text" className="form-control" id="s-l-name"
                                                placeholder="Last name" required />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>Date of birth</label>
                                            <input type="date" className="form-control" id="dob" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Preferred start date</label>
                                            <input type="date" className="form-control" id="start" required />
                                        </div>

                                    </div>
                                    <br />
                                </div>
                            </div>

                            <br />
                            <label htmlFor="checkboxGroup">I am interested in learning more about,</label>
                            <br />
                            <div className="card border register-card">
                                <div className="container">
                                    <br />
                                    <div className="col mt-3 mt-md-0">
                                        <label>Course</label>
                                        <select id={"course"} className="form-control" required>
                                            <option>-----Choose a course you are interested in -----</option>
                                            <option value="tbdp">Total brain development programme</option>
                                            <option value="mathemagics">MatheMagics - Secret to mental maths</option>
                                            <option value="tuteglow">TuteGlow - Expertised EduTutor</option>
                                        </select>
                                        <br />
                                    </div>
                                    <div className="col mt-3 mt-md-0">
                                        <label>Message</label>
                                        <textarea className="form-control" id="message" placeholder="Enter here your questions, if you have any. (optional)">

                                        </textarea>
                                    </div>
                                    <br />
                                </div>
                            </div>

                            <br />
                            <div className="text-center">
                                <button type="submit" className="btn our-btn">
                                    <span style={{ 'display': 'none', 'height': '15px', 'width': '15px' }} id={"contact-loader"}
                                        className="spinner-border text-light" role="status">
                                    </span>Send us your enquiry
                                </button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </section>
    </React.Fragment>
}
