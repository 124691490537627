import React from "react";

export default function About(props) {
  const items = [
    {
      'start': true,
      'img': 'assets/img/history/2016.jpg',
      'year': '2016',
      'description': 'Here is where we commenced.',
      'content': 'First batch certificate function and Talent event of the Total Brain Development Programme (TBDP) that took place on May 14th, 2016, at the premises of Jizan International School in KSA (Kingdom of Saudi Arabia).'
    },
    {
      'img': 'assets/img/history/2017.jpg',
      'year': '2017',
      'description': 'Celebrating excellence!',
      'content': "It's great to share that Aman Mansur was recognized and appreciated for his diligent efforts and hard work in the Mental Mathematics course. Being awarded the Mathemagician award for the year 2017 is a significant accomplishment."
    },
    {
      'img': 'assets/img/history/2018.jpg',
      'year': '2018',
      'description': 'An outstanding achievement unlocked',
      'content': 'A standing ovation for Geet Panjwani on acing the advanced abacus level exam with an incredible 100% score! Your exceptional accomplishment showcases your talent and determination. Keep shining bright!'
    },
    {
      'img': 'assets/img/history/2019.jpg',
      'year': '2019',
      'description': 'Illuminating Young Minds in Abacus',
      'content': 'The snapshot captures a dynamic scene of enthusiastic kids engrossed in an abacus competition. With their hands flying across the abacus beads, these young minds showcase their mathematical acumen and competitive spirit.'
    },
    {
      'img': 'assets/img/history/2020.jpg',
      'year': '2020',
      'description': 'Pinnacle of Performance',
      'content': 'Mohammed Zayanuddin truly stood out as the overall champion in Abacus at our institution. Notably, during the challenging period of the pandemic when classes shifted online, he remarkably achieved a perfect score of 100%.'
    },
    {
      'img': 'assets/img/history/2021.jpg',
      'year': '2021',
      'description': 'Commending Remarkable Achievement',
      'content': 'The image showcases a collection of gleaming trophies arranged elegantly during a certificate distribution event for a talent exam. Each trophy represents a pinnacle of success and hard work.'
    },
    {
      'img': 'assets/img/history/2022.jpg',
      'year': '2022',
      'description': 'Showcasing Brilliance!',
      'content': 'This snapshot offers a glimpse into the heart of the Abacus Talent Show event. Amidst an atmosphere of anticipation, participants and spectators alike gather, surrounded by abacus-themed decorations that evoke a sense of mathematical wonder.'
    },
  ]
  React.useEffect(() => {
    props.setPageTitle('Know more about us');
    props.setPageDescription('Read more on this page to know who we are, what we do, and about our history in the field.');

  }, []);
  return <React.Fragment>
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title text-center">
          <strong><h2>Abdul Kalam Institute for Accelerated Brains</h2></strong>
          <strong><h3>About Us</h3></strong>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="assets/img/about.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <br />
            <div className="alert alert-primary">Welcome to ABKIAB, a sanctuary of learning where brilliance blossoms.</div>
            <p className="fst-italic">
              Expanding its Horizons, <b>Matrix Academy GCC</b>, a stalwart in providing classes Internationally since 2015, introduces an array of new courses including Total Brain Development Programme (TBDP), MatheMagics, and TuteGlow. As part of its evolution, the institution proudly rebrands as the <b>Abdul Kalam Institute for Accelerated Brains</b>, reaffirming its commitment to cultivating young minds.
              <br />At ABKIAB, we believe in crafting a transformative educational journey that transcends conventional boundaries, guiding our students towards a future brimming with intellectual prowess and innovation.
            </p>
            <ul>
              <li><i className="bi bi-check-circle"></i><b> Cutting-edge Pedagogy</b>: At ABKIAB, we ignite a revolution in education with our cutting-edge pedagogical approach. Our curriculum is meticulously designed to cultivate not only academic excellence but also cognitive agility, critical thinking, and problem-solving skills. Through innovative teaching methods, we empower students to unravel the complexities of the world and emerge as versatile thinkers prepared to excel in an ever-evolving landscape.</li>
              <li><i className="bi bi-check-circle"></i><b> Holistic Mental Growth</b>: We stand at the forefront of nurturing holistic mental growth. Beyond textbooks, ABKIAB focuses on fostering emotional intelligence, creativity, and resilience in our students. Our nurturing environment encourages exploration, enabling each child to unlock their full potential and embark on a transformative journey that extends far beyond the classroom.</li>
              <li><i className="bi bi-check-circle"></i><b>Legacy of Excellence</b>: ABKIAB is inspired by the visionary Dr. APJ Abdul Kalam, a luminary known for his dedication to education and innovation. Our institute proudly carries forward his legacy, instilling in our students the values of curiosity, perseverance, and a relentless pursuit of knowledge. Join us in shaping a future where brilliance knows no bounds, as we prepare the leaders of tomorrow to make meaningful contributions to society and beyond.</li>
            </ul>
            <p>
              Join us in this exciting expedition as we shape the leaders and innovators of tomorrow through a focused dedication to education and the cultivation of accelerated mental faculties.
            </p>

          </div>
        </div>

      </div>
    </section>

    <section id="testimonials" className="testimonials">

      <div className="container" data-aos="fade-up">

        <div className="section-title text-center">
          <strong><h2>Our story</h2></strong>
          <strong>Legacy in Retrospect</strong>
        </div>
        <br />
        <br />
        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            {items.map(item => {
              let itemClass = 'testimonial-item shadow-lg';
              if (item.start) {
                itemClass += ' testimonial-item-gold'
              }
              return <div key={item.year} className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className={itemClass}>
                    {item.start ? <h2>{item.year}</h2> : <h3>{item.year}</h3>}
                    <h4>{item.description}</h4>
                    <img src={item.img} className="testimonial-img" alt="" />
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {item.content}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>
            })}

          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
    

  </React.Fragment>
}