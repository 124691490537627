import React from "react";

export default function Home() {
    return <React.Fragment>

        <section id="hero" className="d-flex justify-content-center align-items-center">
            <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">

                <h1>Welcome to the captivating realm of ABKIAB,</h1><br />
                <h3 style={{ "color": "white" }}>an acronym that embodies excellence -
                    <b>
                        <strong>
                            Abdul Kalam Institute for Accelerated Brains
                        </strong>
                    </b>.
                </h3>
                <h2>We are dedicated to nurturing the most precious jewels of our society: our children.</h2>
                <a href="/register" className="btn-get-started">Secure your spot now!</a>

            </div>
        </section>
        <br />
        <br />
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div className="col-lg-12 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                        <div className="content">
                            <h2>Abdul Kalam Institute for Accelerated Brains</h2>
                            <div className="container">
                                <p style={{ 'fontSize': '1.3rem','lineHeight': '1.6' }} className="container">
                                    ABKIAB, which stands for Abdul Kalam Institute for Accelerated Brains, is an institution with a strong focus on education, particularly emphasizing children's mental growth and development.
                                    <br />ABKIAB emerges as a beacon of brilliance, dedicated to nurturing the most precious jewels of our society: <b>our children</b>.
                                     At the heart of ABKIAB's mission lies an unwavering commitment to the mental crescendo of our future leaders. Like a maestro conducting an opulent opus, ABKIAB refines the art of education, synchronizing academic rigour with holistic growth. Our canvas extends beyond textbooks, crafting an immersive experience that nurtures not just knowledge, but character and creativity.
                                    <br />Imagine a place where curiosity is revered, where learning is an exhilarating journey. ABKIAB is more than an institution; it's a cradle of intellectual evolution. Our approach unfurls like a lotus, drawing inspiration from the revered Dr. Abdul Kalam, a luminary who championed the fusion of science and spirit. Just as a lotus blooms in adversity, our students flourish amidst challenges, emerging as agile thinkers and compassionate souls.
                                     ABKIAB's tapestry is woven with threads of innovation, spun by educators who are torchbearers of change. Their dedication is the loom, and the fabric they craft is a future where brilliance knows no bounds. Through experiential learning, cutting-edge pedagogies, and a nurturing ecosystem, ABKIAB ignites a renaissance of cognitive acceleration.
                                    <br />In an era where the world evolves with the speed of thought, ABKIAB stands as a sentinel, shaping intellects that dare to dream and innovate. Our name, Abdul Kalam Institute for Accelerated Brains, resonates like a clarion call for brilliance, beckoning young minds to embrace their limitless potential and soar to realms yet unexplored.
                                    <br /><br /> <i>Welcome to ABKIAB, where education is not just a pursuit, but a symposium of the mind, an odyssey of accelerated cognition, and a testament to the indomitable spirit of human achievement.</i>
                                </p>
                            </div>
                            <div className="text-center text-lg-start">
                                <a href="/about" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>Read More</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                        <img src="assets/img/about.jpg" className="img-fluid" alt=""/>
                    </div> */}

                </div>
            </div>

        </section>
        <section id="services" className="services">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h2>Your Success, Our Commitment</h2>
                    <p>Why Choose ABKIAB?</p>
                </header>

                <div className="row gy-4">

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="service-box blue">
                            <i className="ri-lightbulb-line icon"></i>
                            <h3>Innovative Approach</h3>
                            <p>ABKIAB offers a unique and innovative approach to education that prioritizes mental growth alongside traditional learning.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="service-box orange">
                            <i className="ri-user-2-fill icon"></i>
                            <h3>Inspired by Abdul Kalam</h3>
                            <p>Inspired by the revered scientist and former Indian President, Dr. APJ Abdul Kalam, ABKIAB embodies a dedication to his values of education, curiosity, and innovation.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="service-box green">
                            <i className="ri-book-open-fill icon"></i>
                            <h3>Acceleration of Learning</h3>
                            <p>Our institute focuses on methods that accelerate learning and cognitive development.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="service-box red">
                            <i className="ri-bard-line icon"></i>
                            <h3>Focus on Excellence</h3>
                            <p>The commitment to excellence in education is a dedication to providing high-quality learning experiences for students.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div className="service-box purple">
                            <i className="ri-links-fill icon"></i>
                            <h3>Holistic Development</h3>
                            <p>We focus on a holistic development approach, encompassing not only academic learning but also emotional, social, and cognitive growth.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div className="service-box orange">
                            <i className="ri-presentation-line icon"></i>
                            <h3>Personalized Learning</h3>
                            <p>ABKIAB employs personalized learning approaches, catering to a student's needs and strengths, creating a more tailored educational experience.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div className="service-box green">
                            <i className="ri-leaf-line icon"></i>
                            <h3>Strong Values</h3>
                            <p>Our institute emphasizes strong values such as curiosity, creativity, critical thinking, and a growth mindset, thereby helping children to develop these qualities.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div className="service-box purple">
                            <i className="ri-group-line icon"></i>
                            <h3>Community Engagement</h3>
                            <p>ABKIAB also fosters a sense of community among students, parents, and educators, creating a supportive environment for learning and growth.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div className="service-box pink">
                            <i className="ri-calendar-line icon"></i>
                            <h3>Future Readiness</h3>
                            <p> We focus on preparing students for the challenges of the future, including technological advancements and changes in the job market, ensuring the student's long-term success.</p>

                        </div>
                    </div>

                </div>

            </div>

        </section>

    </React.Fragment>
}